import { useNavigate, useLocation } from 'react-router-dom';
import { AppService } from '../services/app.service';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { useContext } from "react";
import { UserContext } from "../components/context/UserContext.js";
import { userService } from '../services/user.service';

import { USER_URL, USER_BY_PUBLIC_ID_URL } from '../constants/url.constants';

function AddUpdateAdminComponent() {

    const location = useLocation(); 
    const query_params = new URLSearchParams(location.search)  
    const edit_profile_id = query_params.get('v')
    const [adminUsersListResponse, setAdminUsersListResponse] = useState('');

    const { contextState, contextDispatch } = useContext(UserContext);
    const userContextDetails = JSON.parse(contextState)
    const history = useNavigate();

    let initialValues = {}
    if (edit_profile_id === '2'){
        initialValues = {
            first_name: adminUsersListResponse.f_name ? adminUsersListResponse.f_name : '',
            last_name: adminUsersListResponse.l_name ? adminUsersListResponse.l_name : '',
            user_password: adminUsersListResponse.password ? adminUsersListResponse.password : '',
            user_email: adminUsersListResponse.email ? adminUsersListResponse.email : '',
            user_number: adminUsersListResponse.phone_number ? adminUsersListResponse.phone_number : '',
            user_is_admin: 1,
        };
    } else {
        initialValues = {
            user_name: "",
            first_name: "",
            last_name: "",
            user_password: "",
            user_email: "",
            user_number: "",
            user_is_admin: 1,
        };
    }    

    let RegistrationSchema = Yup.object().shape({
        first_name: Yup.string().required("First name is required")
            .min(1, "Minimum characters required are 1")
            .max(100, "Maximum characters required are 100"),
        last_name: Yup.string().required("Last name is required")
            .min(1, "Minimum characters required are 1")
            .max(100, "Maximum characters required are 100"),
        user_number: Yup.string().required("Phone Number is required")
            .matches(/^\d{10,10}$/, "Must be only digits and exactly 10 characters"),
        user_password: Yup.string()
            .required("Password is required")
            .min(7, "Minimum characters required are 7")
            .max(100, "Maximum characters required are 100"),
        user_email: Yup.string().email().required("Email is required"),            
    });

    if (edit_profile_id === '2') {
        RegistrationSchema = Yup.object().shape({
            first_name: Yup.string().required("First name is required")
                .min(1, "Minimum characters required are 1")
                .max(100, "Maximum characters required are 100"),
            last_name: Yup.string().required("Last name is required")
                .min(1, "Minimum characters required are 1")
                .max(100, "Maximum characters required are 100"),
            user_number: Yup.string().required("Phone Number is required")
                .matches(/^\d{10,10}$/, "Must be only digits and exactly 10 characters"),
        }); 
    }
   

    function handleSubmitClick(userRegisterValues) {
        let userItems = {
            'public_id': edit_profile_id === '2' ? adminUsersListResponse.public_id : "",
            'f_name': userRegisterValues.first_name,
            'l_name': userRegisterValues.last_name,
            'password': edit_profile_id === '2' ? adminUsersListResponse.password_hash : userRegisterValues.user_password,
            'email': edit_profile_id === '2' ? adminUsersListResponse.email : userRegisterValues.user_email,
            'phone_number': parseInt(userRegisterValues.user_number),
            'is_admin': edit_profile_id === '2' ? 1 : 1
        }

        AppService
        .makeRequestWithPost(USER_URL, userItems)
        .then(adminResponse => {          
            toast.success(adminResponse.message)
            userloggedInUser()
            history('/superadmin/userreport')
        })
        .catch((message) => {
            toast.error(message.message)
        })
    }

    function userloggedInUser() {
        AppService
        .makeRequest(USER_BY_PUBLIC_ID_URL + userContextDetails.public_id)
        .then(adminUserResponse => {   
            userService.setAuthUserInfo(adminUserResponse.id, adminUserResponse.f_name)
            contextDispatch({ type: 'user', payload: { 'logged_in': true, 'id': userContextDetails.id, 'public_id': userContextDetails.public_id , 'name': adminUserResponse.f_name } })            
            setAdminUsersListResponse(adminUserResponse)
        })
        .catch((message) => {
            toast.error(message.message)
        })
    }

	useEffect(() => {
        userloggedInUser()

	}, [])    

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={RegistrationSchema} onSubmit={(values) => { handleSubmitClick(values); }}>
            {(formik) => {
                return (
                    <div id="task-container" className="container py-5">
                        <h4 className="linetitle mb-4">Add New Admin User</h4>
                        <Form>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <Field type="text" name="first_name" id="first_name" className="form-control" placeholder="First name" />
                                        <ErrorMessage name="first_name" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <Field type="text" name="last_name" id="last_name" className="form-control" placeholder="Last name" />
                                        <ErrorMessage name="last_name" component="span" className="error small text-danger" />
                                    </div>
                                    {edit_profile_id === '1' ? 
                                    <div className="form-group">
                                        <Field type="text" name="user_email" id="user_email" className="form-control" placeholder="Email" />
                                        <ErrorMessage name="user_email" component="span" className="error small text-danger" />
                                    </div>                                    
                                    : '' }
                                    {edit_profile_id === '1' ?
                                    <div className="form-group">
                                        <Field type="text" name="user_password" id="user_password" className="form-control" placeholder="Password" />
                                        <ErrorMessage name="user_password" component="span" className="error small text-danger" />
                                    </div>                                    
                                    : ''}
                                    <div className="form-group">
                                        <Field type="text" name="user_number" id="user_number" className="form-control" placeholder="Phone number" />
                                        <ErrorMessage name="user_number" component="span" className="error small text-danger" />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    )

}

export default AddUpdateAdminComponent;