import React, { useState, useEffect } from 'react';
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify'

import { STATE_URL } from '../constants/url.constants';

function AddStateComponent() {
    const [addState, setAddState] = useState('');
    const [allState, setAllState] = useState('');
    const [publicId, setPublicId] = useState('');

    const handleState = async () => {
        if(addState === 0 || addState === ''){
            toast.error('Invalid state')
            return
        }
        
        AppService
        .makeRequestWithPost(STATE_URL, {'state': addState, 'public_id': publicId ? publicId : ''})
        .then(res => { 
            setAddState('');
            setPublicId('')
            axiosStateResponse()
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }

    const editState = (state) => {
        setPublicId(state.public_id)        
        setAddState(state.state)
    }

    const deleteState = (state) => {
        AppService
        .makeRequestDeleteByPostData(STATE_URL, {"public_id": state.public_id})
        .then(res => {
            axiosStateResponse()
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }

    const axiosStateResponse = async () => {
        AppService
        .makeRequest(STATE_URL)
        .then(state_data => { 
			setAllState(state_data.data)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }

    useEffect(() => {
        axiosStateResponse()
    }, [])


    return (
        <div className="container py-5">
            <h4 className="linetitle mb-4">Add State</h4>
            <div className="row">
                <div className="col-md-8">
                    <div className="input-group mb-3">
                        <input onChange={(e) => setAddState(e.target.value)} value={addState} className="form-control" id="title" type="text" placeholder="Add State here.." />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={handleState} type="submit" id="button-addon2">Add</button>
                        </div>
                    </div>

                    <ul className="list-group list-group-flush">
                        {allState ? (
                            allState.map(function (eachState, index) {
                                return (
                                    <li className="list-group-item d-flex px-0" key={index}>
                                        {eachState.state}
                                        <button onClick={() => editState(eachState)} className="btn btn-sm btn-warning ml-auto mr-2"><i className="fa fa-pencil mr-1"></i>Edit</button>
                                        <button onClick={() => deleteState(eachState)} className="btn btn-sm btn-danger delete"><i className="fa fa-trash mr-1"></i>Delete</button></li>

                                )
                            })
                        ) : null}
                    </ul>

                </div>
            </div>
        </div>
    )

}

export default AddStateComponent;