import './utilities/css/style.css'
import LandingComponent from './components/LandingComponent'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  window.scrollTo(0,0)
  return (
    <>
      <ToastContainer 
        position="top-right"
        autoClose={4000}
        // hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        // draggable
        pauseOnHover
      />
      <LandingComponent></LandingComponent>
    </>
  );
}

export default App;
