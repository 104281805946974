import axios from 'axios';

/**
 * Sets HTTP Headers with Authorization key
 * @returns {object} headers
 */
export const HTTPBaseHeaders = () => {
    return {
        headers: {
        // Authorization: `${getSessionVariable(AUTH_TOKEN_NAME)}`,
        'Content-type': 'application/json; charset=UTF-8'
        }
    }
}

export const HTTPTokenHeaders = () => {
    return {
        headers: {
        // Authorization: `${getSessionVariable(AUTH_TOKEN_NAME)}`,
        'Content-type': 'application/json; charset=UTF-8'
        }
    }
}

export const AppService = {
  
    /**
     * Make a fetch API call
     * @param {string} url fully qualified url
     * @param {object} requestOptions options object from options()
     */
  
    makeRequest: async (url, post_type='get', post_data, headers) => {
      // const response = await axios(url)
      let response = null

      switch(post_type) {
        case 'get':
          response = await axios(url)
          break;
        case 'post':
          // code block
          break;
        case 'delete':
          response = await axios.delete(url, {data:post_data})
          break;          
        default:
          // code block
      }

      let result = null
  
      if (response.status === 200 || response.status === 201) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    },


    makeRequestWithQueryString: async (url, query_string) => {
      const final_query = query_string ? url+'?'+query_string : url
      const response = await AppService.makeRequest(final_query, 'get')
      return response
    },  
    
    
    makeRequestDeleteById: async (url, item_id) => {
      const response = await axios.delete(url, {data:item_id})
      let result = null
  
      if (response.status === 200) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    },


    makeRequestDeleteByPostData: async (url, post_data) => {
      // const response = await axios.delete(url, {data:post_data})
      // const final_query = query_string ? url+'?'+query_string : url
      const response = await AppService.makeRequest(url, 'delete', post_data)
      return response
    },     

    makeRequestDeleteByPostDataV1: async (url, post_data) => {
      const response = await axios.delete(url, {data:post_data})
      let result = null
  
      if (response.status === 200) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    },    

    makeRequestWithHeaders: async (url, headers_data) => {
      const response = await axios(url, {headers:headers_data})
      let result = null
  
      if (response.status === 200) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    },    


    makeRequestWithPost: async (url, post_data) => {
      const response = await axios.post(url, post_data)
      let result = null
  
      if (response.status === 200 || response.status === 201) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    },

    makeRequestWithPostAndHeaders: async (url, post_data, headers_data) => {
      const response = await axios.post(url, post_data, headers_data)
      let result = null
  
      if (response.status === 200 || response.status === 201) {
        result = await response.data
      } else {
        throw response
      }
  
      if (response.status >= 400) {
        throw result
      }

      return result
    }    

}
















