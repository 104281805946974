import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useLocation  } from 'react-router-dom';
import * as Yup from 'yup';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw } from 'draft-js';
import { AppService } from '../services/app.service';
import { useContext } from "react";
import { UserContext } from "../components/context/UserContext.js";
import { toast } from 'react-toastify'

import { CLASSIFIED_URL, DISTRICT_BY_STATE_ID_URL, CATEGORY_URL, 
    STATUS_URL, STATE_URL, CLASSIFIED_BY_PUBLIC_ID_URL, CLASSIFIED_IMAGE_URL } from '../constants/url.constants';

function ClassifiedUpdateComponent() {

    const pathHistory = useNavigate()
    const location = useLocation()
    const query_params = new URLSearchParams(location.search)  
    const classified_public_id = query_params.get('public_id')

    const [allCategory, setAllCategory] = useState('');
    const [allStatus, setAllStatus] = useState('');
    const [allState, setAllState] = useState('');
    const [allDistrict, setAllDistrict] = useState('');
    const [adsToUpdateResponse, setAdsToUpdateResponse] = useState('');
    const [updateDesc, setUpdateDesc] = useState(false)

    const { contextState, contextDispatch } = useContext(UserContext);
    const userContextDetails = JSON.parse(contextState)
    const [imgPath, setImagePath] = useState('');

    let initialValues = {
        classified_title: '',
        classified_desc: '',
        classified_image: '',
        classified_category_id: '',
        classified_status_id: '',
        classified_state_id: '',
        classified_district_id: '',
        classified_zipcode: '',
        classified_users_id: userContextDetails.id,
        classified_phone_number: '',
        classified_expire_days: '',
        classified_is_hide: '',
    };

    if (adsToUpdateResponse) {     
        initialValues = {
            classified_title: adsToUpdateResponse.title ? adsToUpdateResponse.title : '',
            classified_desc: adsToUpdateResponse.description ? adsToUpdateResponse.description : '',
            classified_image: adsToUpdateResponse.image_file_name ? adsToUpdateResponse.image_file_name : '',
            classified_category_id: adsToUpdateResponse.category.id,
            classified_status_id: adsToUpdateResponse.status.id,
            classified_state_id: adsToUpdateResponse.state.id,
            classified_district_id: adsToUpdateResponse.district.id,
            classified_zipcode: adsToUpdateResponse.zip_code ? adsToUpdateResponse.zip_code : "",
            classified_users_id: adsToUpdateResponse.user.id,
            classified_phone_number: adsToUpdateResponse.phone_number ? adsToUpdateResponse.phone_number : '',
            classified_expire_days:adsToUpdateResponse.to_be_expire_on,
            classified_is_hide: adsToUpdateResponse.is_hidden,
        };
    }   

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

    const adsSchema = Yup.object().shape({
        classified_image: Yup.mixed()
            .when({
                is: (value) => typeof value === 'string',
                then: Yup.string().test("file_format", "Uploaded 11 file has unsupported format.", value => value.length <= 60),
                otherwise: Yup.mixed()
                .test("file_size", "Uploaded file is too big.", value => !value || (value && value.size <= 15000000))
                .test("file_format", "Uploaded file has unsupported format.", value => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
            }),
        classified_category_id: Yup.string().required("Please select category")
        .test("file_size", "Uploaded file is too big.", value => !value || (value >= 1)),
        classified_status_id: Yup.string().nullable().required("Please select status"),
        classified_state_id: Yup.string().nullable().required("Please select state"),
        classified_district_id: Yup.string().nullable().required("Please select district"),
        classified_phoneno: Yup.string()
            .matches(/^\d{10,10}$/, "Must be only digits and exactly 10 characters")
            .nullable(),
        classified_zipcode: Yup.string().nullable()
            .matches(/^\d{6,6}$/, "Must be only digits and exactly 6 characters"),
        classified_expire_days: Yup.string()
            .matches(/^\d+$/, "Must be digits and characters required are 1."),            
        classified_is_hide: Yup.number().typeError('Please select hide or show').required("You must select Hide or Show"),
    });


    const handleUpdateSubmitClick = (adsUpdatedValues) => {
        axiosAddClassifiedResponse(adsUpdatedValues)
    }    

    const axiosAddClassifiedResponse = async (adsUpdatedValues) => {
        const formData = new FormData();
        if (adsUpdatedValues.classified_image) {
            formData.append('image_file_name', adsUpdatedValues.classified_image)
        }    

        formData.append('public_id', adsToUpdateResponse.public_id);
        formData.append('title', adsUpdatedValues.classified_title);
        formData.append('description', adsUpdatedValues.classified_desc);
        formData.append('state_id', adsUpdatedValues.classified_state_id);
        formData.append('district_id', adsUpdatedValues.classified_district_id);
        formData.append('zip_code', adsUpdatedValues.classified_zipcode ? adsUpdatedValues.classified_zipcode : 0);
        formData.append('is_hidden', adsUpdatedValues.classified_is_hide);
        formData.append('category_id', adsUpdatedValues.classified_category_id);
        formData.append('status_id', adsUpdatedValues.classified_status_id);
        formData.append('user_id', adsUpdatedValues.classified_users_id);
        formData.append('phone_number', adsUpdatedValues.classified_phone_number ? adsUpdatedValues.classified_phone_number : 0);
        formData.append('to_be_expire_on', adsUpdatedValues.classified_expire_days ? adsUpdatedValues.classified_expire_days : 0);
        
        const headers = {"content-type": "multipart/form-data"}

        AppService
        .makeRequestWithPostAndHeaders(CLASSIFIED_URL, formData, { headers })
        .then(response => {          
            pathHistory('/superadmin/myads')
            toast.success(response.message)
        })
        .catch((res) => {
            toast.error(res.message)
        }) 
    }    


    const onStateChange = async (stateValueId) => {
        if (stateValueId) {
            AppService
            .makeRequestWithQueryString(DISTRICT_BY_STATE_ID_URL + stateValueId)
            .then(districResponse => {          
                setAllDistrict(districResponse.data)
            })
            .catch((res) => {
                toast.error(res.message)
            })            
        }
    }    

    useEffect(() => {
		window.scrollTo(110, 110)
		Promise.all([
			AppService.makeRequest(CATEGORY_URL),
			AppService.makeRequest(STATUS_URL),
			AppService.makeRequest(STATE_URL),
            AppService.makeRequest(CLASSIFIED_BY_PUBLIC_ID_URL + classified_public_id)
		]).then(([categoryResponse, statusResponse, stateResponse, singleClassifiedResponse]) => {
			setAllCategory(categoryResponse.data)
			setAllStatus(statusResponse.data)
			setAllState(stateResponse.data)
            setAdsToUpdateResponse(singleClassifiedResponse)
            onStateChange(singleClassifiedResponse.state.id)
            setImagePath(singleClassifiedResponse.image_file_name)
		})        
    }, [classified_public_id])

    function returnToPrev() {
        pathHistory(-1)
    }    

    const updateDescClickV2 = async () => {
        setUpdateDesc(true)
    }
    const updateDescClickV1 = async () => {
        setUpdateDesc(false)
    }    

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={adsSchema} onSubmit={(values) => { handleUpdateSubmitClick(values); }}>
            {(formik) => {
                const { handleChange, setFieldValue, values } = formik;
                return (
                    <div className="task-container container py-5">
                        <Form>
                            <div className="col-md-8">
                                <h3 className="linetitle mb-4">Update Classified ...</h3>
                                <div className="form-group">
                                    <label className="form-control-label" >Ad Title :</label>
                                    <Field className="form-control" id="classified_title" type="text" name="classified_title" placeholder="ad title" />
                                    <ErrorMessage name="classified_title" component="span" className="error small text-danger" />
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label" >Ad Description :</label>
                                    { updateDesc
                                        ?
                                        <>
                                    <Editor
                                        editorStyle={{ height: '200px' }}
                                        className="form-control"
                                        id="classified_desc"    
                                        name="classified_desc"
                                        onContentStateChange={(editorState) => 
                                            setFieldValue("classified_desc", draftToHtml(editorState))
                                        }
                                    />                                            
                                            <button className="btn btn-info btn-sm" onClick={updateDescClickV1}> Back </button>
                                        </>    
                                    :
                                        <>
                                            <p className="card-text" dangerouslySetInnerHTML={{__html: (initialValues.classified_desc !== '[object Object]' ? initialValues.classified_desc : '')}}></p>                                            
                                            <button className="btn btn-info btn-sm" onClick={updateDescClickV2}> Update Description </button>
                                        </>
                                    }
                                    {/* <Field className="form-control" id="classified_desc" type="text" name="classified_desc" placeholder="ad title" /> */}
                                            
                                    <ErrorMessage name="classified_desc" component="span" className="error small text-danger" />
                                </div>
                                <div className="form-group">

                                </div>

                                <div className="form-group">
                                    <div className="custom-file">
                                        <label className="custom-file-label" htmlFor="classified_image" >{imgPath !=='' ? imgPath : 'Upload ads image here'}</label><label className="custom-file-label" htmlFor="classified_image" >{imgPath !== '' ? imgPath : ''}</label>
                                        <input className="custom-file-input" id="classified_image" onChange={(e) => {setFieldValue("classified_image", e.target.files[0]) ; setImagePath(e.target.files[0].name)} } type="file" accept="image/png, image/jpeg" name="classified_image" />
                                        <ErrorMessage name="classified_image" component="span" className="error small text-danger" />
                                    </div>
                                </div>
                                    {adsToUpdateResponse ? 
                                    <div className="form-group product-thumb">
                                        <label className="form-control-label">Image :</label>
                                        <img src={values.classified_image ? CLASSIFIED_IMAGE_URL + values.classified_image : ''} alt=" [ No Ads ] " className="img-fluid" />
                                    </div>
                                    : ''
                                    }

                                <div className="form-group">
                                        <label className="form-control-label" >Ad Category :</label>
                                    <Field as="select" name="classified_category_id" className="custom-select">
                                        {adsToUpdateResponse ?
                                            allCategory.map((category, index) => {
                                                return <option key={index} value={category.id}>{values.classified_category_id === category.id ? category.category : category.category}</option>
                                            }) : <option >none</option>
                                        }
                                    </Field>
                                    <ErrorMessage name="classified_category_id" component="span" className="error small text-danger" />
                                    </div>
                                <div className="form-group">
                                        <label className="form-control-label" >Ad Status :</label>
                                    <Field as="select" name="classified_status_id" className="custom-select">
                                        {adsToUpdateResponse ?
                                            allStatus.map((status, index) => {
                                            return <option key={index} value={status.id}>{values.classified_status_id === status.id ? status.status : status.status}</option>  
                                            }) : <option >none</option>
                                        }
                                    </Field>
                                    <ErrorMessage name="classified_status_id" component="span" className="error small text-danger" />
                                    </div>

                                <div className="form-group">
                                        <label className="form-control-label" >Ad Location State :</label>
                                    <Field as="select" name="classified_state_id" onChange={(e) => { handleChange(e); onStateChange(e.target.value, setFieldValue); }} className="custom-select">
                                        {adsToUpdateResponse ?
                                            allState.map((state, index) => {
                                                return <option key={index} value={state.id}>{values.classified_state_id === state.id ? state.state : state.state}</option>  
                                            }) : <option >none</option>
                                        }
                                    </Field>
                                    <ErrorMessage name="classified_state_id" component="span" className="error small text-danger" />
                                    </div>
                                <div className="form-group">
                                        <label className="form-control-label" >Ad Location District :</label>
                                    <Field as="select" name="classified_district_id" className="custom-select">
                                        <option value="">Select</option>
                                        {allDistrict ?
                                            allDistrict.map((district, index) => {
                                                return <option key={index} value={district.id}>{district.district}</option>
                                            }) : <option >none</option>
                                        }
                                    </Field>
                                    <ErrorMessage name="classified_district_id" component="span" className="error small text-danger" />
                                    </div>                                    
                                <div className="form-group">
                                <label className="form-control-label" >Ad Location ZipCode :</label>
                                    <Field className="form-control" id="classified_zipcode" type="text" name="classified_zipcode" placeholder="zip code" />
                                    <ErrorMessage name="classified_zipcode" component="span" className="error small text-danger" />
                                    </div>

                                <div className="form-group">
                                <label className="form-control-label" >Ad Phone Number :</label>
                                    <Field className="form-control" id="classified_phone_number" type="text" name="classified_phone_number" placeholder="phone number" />
                                    <ErrorMessage name="classified_phone_number" component="span" className="error small text-danger" />
                                    </div>
                                <div className="form-group">
                                    <label className="form-control-label" >Ad Expire Day :</label>
                                    <Field className="form-control" id="classified_expire_days" type="text" name="classified_expire_days" placeholder="No of days to Ads expire" />
                                    <ErrorMessage name="classified_expire_days" component="span" className="error small text-danger" />
                                </div>                                    
                                    <div className="form-group">
                                        <div className="custom-control-inline">
                                            <Field className="margin:5px" id="classified_is_hide" type="radio" name="classified_is_hide" value="1"/>
                                            <label >Hide advertisement </label>                                            
                                        </div>
                                        <div className="custom-control-inline">
                                            <Field className="margin:5px" id="classified_is_hide" type="radio" name="classified_is_hide" value="0"/>
                                            <label>Show advertisement  </label>
                                        </div>
                                        <ErrorMessage name="classified_is_hide" component="span" className="error small text-danger" />                                          
                                    </div>                                

                                <div>
                                    <button onClick={returnToPrev} className="btn btn-secondary mr-3">Back</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                );
            }}                
        </Formik>
    )
      
}

export default ClassifiedUpdateComponent;