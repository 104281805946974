import { useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import {VerticleButton as ScrollUpButton} from "react-scroll-up-button"; //Add this line Here
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify'

import { CLASSIFIED_URL, CATEGORY_URL, DISTRICT_URL, CLASSIFIED_BY_PUBLIC_ID_URL, CLASSIFIED_IMAGE_URL, CLASSIFIED_BY_PUBLIC_ID_SM_URL } from '../constants/url.constants';

function HomePageComponent() {

	const [allClassifieds, setAllClassifieds] = useState('');
	const [originalAllClassifieds, setOriginalAllClassifieds] = useState('');
	const [disctrictId, setDisctrictId] = useState('');
	const [categoryId, setCategoryId] = useState('');
	const [allDistrict, setAllDistrict] = useState('');
	const [searchText, setSerachText] = useState('');
	const [allCategory, setAllCategory] = useState('');

	const handleChange = (searchFieldText) => {
		setSerachText(searchFieldText)
		searchFieldText = searchFieldText.toLowerCase().trim()
		
		if (!searchFieldText) {
			fetchData(CLASSIFIED_URL, `is_hidden=0`)
		} else {
			const filteredData = originalAllClassifieds.filter(item => {
				return Object.keys(item).some(key => {
					if (item[key]) {
						return item[key].toString().toLowerCase().includes(searchFieldText)
					} else {
						return false;
					}
				})
			})
			setAllClassifieds(filteredData)
		}
	}

	let fetchData = (url, build_query_3) => {
        AppService
        .makeRequestWithQueryString(url, build_query_3)
        .then(filtered_classified_data => {          
			setAllClassifieds(filtered_classified_data.data)
        })
        .catch((res) => {
			toast.error(res.message)     
		})			
	}	

	const handleUpdateFilter = (filterDictValue) => {
		setDisctrictId(filterDictValue["district_id"])
		setCategoryId(filterDictValue["category_id"])
		let build_query_1 = filterDictValue.district_id ? filterDictValue.district_id : ''
		let build_query_2 = filterDictValue.category_id ? filterDictValue.category_id : ''
		let build_query_3 = null
		if(build_query_1 && build_query_2){
			build_query_3 = `district_id=`+ build_query_1 + `&category_id=`+ build_query_2 + `&is_hidden=0`
		} else if (build_query_1 && !build_query_2){
			build_query_3 = `district_id=`+ build_query_1 + `&is_hidden=0`
		} else if (!build_query_1 && build_query_2){
			build_query_3 = `category_id=`+ build_query_2 + `&is_hidden=0`
		} else {
			build_query_3 = `is_hidden=0`
		}

		fetchData(CLASSIFIED_URL, build_query_3)
	}	

	const clearSearchText = (e) => {
		e.preventDefault();
		setSerachText('')
		fetchData(CLASSIFIED_URL)
	}

	useEffect(() => {
		window.scrollTo(110, 110)
		Promise.all([ 
			AppService.makeRequest(CATEGORY_URL),
			AppService.makeRequest(DISTRICT_URL),
			AppService.makeRequestWithQueryString(CLASSIFIED_URL, `is_hidden=0`),
		]).then(([categoryResponse, districtResponse, classifiedResponse]) => {
			setAllCategory(categoryResponse.data)						
			setAllDistrict(districtResponse.data)
			setAllClassifieds(classifiedResponse.data)
			setOriginalAllClassifieds(classifiedResponse.data)
		}
	)

	}, [])


	function openWhatsApp(classifiedPublicId) {
		window.open('whatsapp://send?text='+ CLASSIFIED_BY_PUBLIC_ID_SM_URL + classifiedPublicId);
	}

	return (
		<div>
			<section className="hero-area bg-1 text-center text-white overlay">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="content-block">
								<h1 className="">Advertisements Near You</h1>
								<div className="short-popular-category-list text-center">
									<ul className="list-inline text-uppercase mt-3 locations">
										<li className="list-inline-item">
											<Link to={""} onClick={() => handleUpdateFilter({district_id : '', category_id : categoryId})} className="text-white px-3 py-1 text-decoration-none" > All</Link>
										</li>
										{allDistrict ?
											Object.keys(allDistrict).map((key) => {
												return (
													<li className="list-inline-item" key={key}>
														<Link to={""} onClick={() => handleUpdateFilter({district_id : allDistrict[key].id, category_id : categoryId})} className="text-white px-3 py-1 border-left text-decoration-none" > {allDistrict[key].district}</Link>
													</li>
												)
											}
											)
											:
											""
										}
									</ul>
								</div>
							</div>
							<div className="advance-search">
								<form action="#">
									<div className="row justify-content-center">
										<div className="col-md-8">
											<div className="rounded-pill bg-white d-flex align-items-center p-2">
												<input type="text" onChange={(e) => handleChange(e.target.value)} value={searchText} className="form-control bg-transparent border-0" id="ad_search" placeholder="Search for ads" />
												<button type="submit" onClick={clearSearchText} className="btn btn-danger btn-main  rounded-pill py-2 px-4  ">Clear</button>
											</div>
										</div>
									</div>
								</form>

							</div>

						</div>
					</div>
				</div>
			</section>

			<section className="popular-deals section bg-gray">
				<div className="container py-5">
					<div className="short-popular-category-list text-center">
						<h2 className="heading w-75 m-auto">Ads Categories</h2>
						<ul className="nav nav-pills justify-content-center mb-3">
							<li className="list-inline-item">
								<Link className="nav-link btn-brand rounded-pill text-white" to={""} onClick={() => handleUpdateFilter({district_id : disctrictId, category_id : ''})}> All</Link>
							</li>							
							{allCategory ?
								Object.keys(allCategory).map((key) => {
									return (
										<li className="list-inline-item" key={key}>
											<Link className="nav-link btn-brand rounded-pill text-white" to={""} onClick={() => handleUpdateFilter({district_id : disctrictId, category_id : allCategory[key].id})} > { allCategory[key].category}</Link>
										</li>
									)
									}
								)							
								:
								""
							}
						</ul>
					</div>

					<h2 className="linetitle mb-4">Trending Ads</h2>

					<div className="row all-products">
					<ScrollUpButton />
						{allClassifieds ?
								Object.keys(allClassifieds).map((key) => {
									return (
										<div className="col-sm-12 col-lg-4 mb-4" key={key}>
											<div className="product-item rounded-4">
												<div className="card border-0 mb-0 rounded-lg shadow-pop border-primary">
														{allClassifieds[key].image_file_name ?
															<img className="card-img-top img-fluid shadow-pop" src={allClassifieds[key].image_file_name ? CLASSIFIED_IMAGE_URL + allClassifieds[key].image_file_name : ''} alt={allClassifieds[key].image_file_name ? allClassifieds[key].image_file_name : 'No Image'} />
															: ''
														}
													
													<div className="card-body rounded-lg shadow-pop pb-2">
														{allClassifieds[key].title !== '-' ?
															<h4 className="card-title text-dark text-decoration-none">{allClassifieds[key].title}</h4>
															: ''}
														{allClassifieds[key].description !== '-' ?	
															<p className="card-text" dangerouslySetInnerHTML={{__html: (allClassifieds[key].description !== '[object Object]' ? allClassifieds[key].description.replace('/\n/g', '<br />') : '')}}></p>
															: ''}
														<p className="small text-muted mb-0 row d-inline">
															<i className="fa fa-map-marker mr-0 mb-0 col-1"></i>{allClassifieds[key].district.district}  
															<i className="fa fa-key fa-fw mr-0 mb-0 col-1"></i>{allClassifieds[key].category.category}
															<i className="fa fa-calendar mr-0 mb-0 col-1"></i>{new Date(allClassifieds[key].registered_on).toISOString().slice(0, 10)}
														</p>
													</div>
													<div className="card-footer d-flex justify-content-between bg-white rounded-lg shadow-pop pb-1 d-inline">

														<p className="card-text mb-0 fa fa-phone mr-0 align-item-center pt-2">{typeof allClassifieds[key].phone_number !== 'undefined' && allClassifieds[key].phone_number !=='' && allClassifieds[key].phone_number !== 'null' ? <a href={'tel:+91' + allClassifieds[key].phone_number } className="text-dark text-decoration-none"><i></i> {allClassifieds[key].phone_number}</a> : ''}</p>
														<i className="fa fa-whatsapp text-success fa_custom fa-2x" onClick={() => openWhatsApp(allClassifieds[key].public_id)} />
													</div>
												</div>
											</div>
										</div>
									)
							}) :
							<div className="col-sm-12"><h4>None</h4></div>
						}
					</div>
				</div>
			</section>

		</div>
	)

}

export default HomePageComponent;