import { useNavigate } from 'react-router-dom';
import { UserContext } from "../components/context/UserContext.js";
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify'

function LogoutComponent() {
    const history = useNavigate();
    const { contextState, contextDispatch } = useContext(UserContext);
    const contextUserDetail = JSON.parse(contextState)
    useEffect(() => {
        if (contextUserDetail.id) {
            localStorage.clear();
            contextDispatch({ type: 'user', payload: { 'logged_in': false, 'id': null, 'public_id': null , 'name': null } })
            toast.success('Successfully logged out..')     
        }
    })

    function handleLogin() {
        history('/superadmin/login')
    }

    return (
        <div id="task-container" className="container py-5">

            <h4>You have logged out successfully</h4>
            <p className="text-muted">Would you like to login again. Please click here..    <a href="/superadmin" onClick={() => { handleLogin() }}>Login</a></p>
        </div>
    )
}

export default LogoutComponent;