import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { AppService } from '../services/app.service';

import { USER_URL } from '../constants/url.constants';
import { toast } from 'react-toastify'

const columns = [
	{
		name: 'ID',
		selector: row => row.id,
		sortable: true,
	},
	{
		name: 'First name',
		selector: row => row.f_name,
		sortable: true,
	},
	{
		name: 'Last name',
		selector: row => row.l_name,
		sortable: true,
	},
	{
		name: 'Email',
		selector: row => row.email,
		sortable: true,
	},
	{
		name: 'Phone number',
		selector: row => row.phone_number,
		sortable: true,
	},
	{
	    name: 'Is_admin',
		selector: row => row.is_admin ? 'Yes' : 'No',
		sortable: true,
	},
];


function UserReportComponent() {
	const [usersListResponse, setUsersListResponse] = useState('');

	useEffect(() => {
        AppService
        .makeRequest(USER_URL)
        .then(adminResponse => {   
			setUsersListResponse(adminResponse.data)
        })
        .catch((message) => {
			toast.error(message.message)
        }) 		
	}, [])

	return (
		<div>
			<section className="popular-deals section bg-gray">
				<div className="container py-5">
					<h4 className="linetitle mb-4">Admin Users List</h4>
					<div className="row">
						<div className="col-md-12">
							<DataTable
								columns={columns}
								data={usersListResponse}
								pagination
							/>
						</div>
					</div>
				</div>
			</section>
		</div>
	)

}

export default UserReportComponent;