import { useNavigate, Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { UserContext } from "../components/context/UserContext.js";
import { AppService } from '../services/app.service';
import { userService } from '../services/user.service';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify'

import { USER_AUTH_LOGIN_URL, USER_BY_PUBLIC_ID_URL } from '../constants/url.constants';

function LoginComponent() {
    const history = useNavigate();
    const { contextState, contextDispatch } = useContext(UserContext);

    const initialValues = { email: "", password: "" };
    const SignInSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .min(4, "Password is too short - should be 4 chars minimum")
            .max(15, "Password is too long - should be less than 20 chars"),
    });


    async function handleLogin(login_info) {
        AppService
        .makeRequestWithPost(USER_AUTH_LOGIN_URL, {"email": login_info.email,"password": login_info.password})
        .then(LoginResponse => {
            if(LoginResponse.status === 'success') {
                userService.setAuthTokenInfo(LoginResponse.Authorization)
                const user_public_id = userService.getAuthBaseInfo()
                if(user_public_id !== ''){
                    AppService
                    .makeRequest(USER_BY_PUBLIC_ID_URL + user_public_id)
                    .then(userResponse => {
                        toast.success(userResponse.message)     
                        userService.setAuthUserInfo(userResponse.id, userResponse.f_name)
                        contextDispatch({ type: 'user', payload: { 'logged_in': true, 'id': userResponse.id, 'public_id': userResponse.public_id , 'name': userResponse.f_name } })
                        history('/')
                    })
                } else {
                    history('/superadmin/login/')        
                    toast.error('Invalid login credentials..')                       
                }
            } else {
                history('/superadmin/login/')  
                toast.error(LoginResponse.message)        
            }
        })
        .catch((message) => {
            history('/superadmin/login/')     
            toast.error(message.message)     
        })
    }

    return (
        <Formik initialValues={initialValues} validationSchema={SignInSchema} onSubmit={(values) => { handleLogin(values); }}>
            {(formik) => {
                return (
                    <div id="task-container" className="container py-5">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <Form className="shadow p-5">
                                    <h4 className="heading text-center mb-4" >Login</h4>
                                    <div className="form-group">
                                        <Field type="text" name="email" id="email" className="form-control" placeholder="email" />
                                        <ErrorMessage name="email" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <Field type="password" name="password" id="password" className="form-control" placeholder="Password" />
                                        <ErrorMessage name="password" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <Link to="/superadmin/forgotpassword/?v=1">Forgot password?</Link>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    )
}

export default LoginComponent;