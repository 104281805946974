import React, { useState } from 'react';
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { CLASSIFIED_HIDE_AT_ONCE_URL } from '../constants/url.constants';

function HideAdsBulkUpdateComponent() {
    const [adsHideDays, setAdsHideDays] = useState('');
    const history = useNavigate();

    const axiosBulkHideResponse = async () => {
        if(adsHideDays === 0 || adsHideDays === ''){
            toast.error('Invalid number of days')  
        } else {
            AppService
            .makeRequestWithPost(CLASSIFIED_HIDE_AT_ONCE_URL, {"no_of_days": adsHideDays})
            .then(hideDataResponse => {
                toast.success(hideDataResponse.message)  
                history('/superadmin/myads')        
            })
            .catch((message) => {
                toast.error(message.message)
            })
        }
    }

    return (
        <div className="container py-5">
            <h4 className="linetitle mb-4">Hide your old ads</h4>
            <div className="row">
                <div className="col-md-8">
                    <div className="input-group">
                        <input onChange={(e) => setAdsHideDays(e.target.value)} value={adsHideDays} className="form-control" id="title" type="text" placeholder="No of days to hide ads. example : 5" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={axiosBulkHideResponse} type="submit" id="button-addon2">Add</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default HideAdsBulkUpdateComponent;