
// import METHOD_POST, BASE_API_URL, LOGIN  from '/constants/url.constants'

// import { AppService, HTTPBaseHeaders } from './app.service'
import jwt from 'jsonwebtoken'



export const userService = {
  // token: async (data, signal) => {
  //   // sessionTimer(false) // stop
  //   const requestData = {
  //     email: data.username,
  //     password: data.password
  //   }
  //   let response = null
  //   response = await AppService.makeRequestWithHeaders(BASE_API_URL + LOGIN, {
  //     METHOD_POST,
  //     // signal,
  //     ...HTTPBaseHeaders(),
  //     body: JSON.stringify(requestData)
  //   })

  //   const jwtToken = jwt.decode(response.Authorization)
  //   sessionStorage.setItem("token",jwtToken);
  //   // const { exp } = jwtToken
  //   // storeToken(response)
  //   // setInitialLoginTime(response)
  // },
  setAuthTokenInfo : (data) => {
    localStorage.setItem('token_info', data);
    userService.setAuthBaseInfo(data)
  },
  setAuthBaseInfo : (data) => {
    const jwtToken_decoded = jwt.decode(data)
    localStorage.setItem('base_info', jwtToken_decoded.sub)
  },
  setAuthUserInfo : (id, name) => {
    localStorage.setItem('user_info', JSON.stringify({'u_id':id, 'u_name':name}));
  },   
  getAuthTokenInfo : () => {
    return localStorage.getItem('token_info');
  },  
  getAuthBaseInfo : () => {
    return localStorage.getItem('base_info');
  },
  getAuthUserInfo : () => {
    return localStorage.getItem('user_info');
  },   

  // logout: async () => {
  //   let href_value = LOGIN
  //   // if(isAdmin()){
  //   //   href_value = '/admin-login'
  //   // }
  //   // clearAllSession()
  //   // toast.success(LOGOUT_SUCCESSFUL)
  //   setTimeout(() => {
  //     window.location.href = href_value
  //   }, 3000)
  // },
}

// export const userService = {
//     login: async (data, signal) => {
//       // sessionTimer(false) // stop
//       const requestData = {
//         email: data.username,
//         password: data.password
//       }
//       let response = null
//       response = await AppService.makeRequestWithHeaders(BASE_API_URL + LOGIN, {
//         METHOD_POST,
//         // signal,
//         ...HTTPBaseHeaders(),
//         body: JSON.stringify(requestData)
//       })
  
//       const jwtToken = jwt.decode(response.Authorization)
//       sessionStorage.setItem("token",jwtToken);
//       // const { exp } = jwtToken
//       // storeToken(response)
//       // setInitialLoginTime(response)
//     },
//     logout: async () => {
//       let href_value = LOGIN
//       // if(isAdmin()){
//       //   href_value = '/admin-login'
//       // }
//       // clearAllSession()
//       // toast.success(LOGOUT_SUCCESSFUL)
//       setTimeout(() => {
//         window.location.href = href_value
//       }, 3000)
//     },
// }