import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify'
import axios from 'axios';

import { useContext } from "react";
import { UserContext } from "../components/context/UserContext.js";

import { CATEGORY_URL, STATE_URL, DISTRICT_BY_STATE_ID_URL, STATUS_URL, CLASSIFIED_URL } from '../constants/url.constants';

function AddClassifiedsComponent(props) {
    const { contextState, contextDispatch } = useContext(UserContext);
    const userContextDetails = JSON.parse(contextState)
    
    const history = useNavigate();
    const [allCategory, setAllCategory] = useState('');
    const [allStatus, setAllStatus] = useState('');
    const [allState, setAllState] = useState('');
    const [allDistrict, setAllDistrict] = useState('');
    const [imgPath, setImagePath] = useState('');    

    const initialValues = {
        classified_title: "",
        classified_desc:convertToRaw(ContentState.createFromText('')),
        classified_image: "",
        classified_category_id: "",
        classified_status_id: "",
        classified_state_id: "",
        classified_district_id: "",
        classified_zipcode: "",
        classified_users_id: userContextDetails.id,
        classified_is_hide: "",
        classified_phonenum: "",
        classified_expire_days:""
    };

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

    const adsSchema = Yup.object().shape({
        classified_image: Yup.mixed()
            .test("file_size", "Uploaded file is too big.", value => !value || (value && value.size <= 15000000))
            .test("file_format", "Uploaded file has unsupported format.Please use jpeg, jpg, png", value => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
        classified_category_id: Yup.string().required("Please select category"),
        classified_status_id: Yup.string().required("Please select status"),
        classified_state_id: Yup.string().nullable().required("Please select state"),
        classified_district_id: Yup.string().nullable().required("Please select district"),
        classified_phonenum: Yup.string()
            .matches(/^\d{10,10}$/, "Must be digits and characters required are 10."),
        classified_zipcode: Yup.string()
            .matches(/^\d{6,6}$/, "Must be digits and characters required are 6."),
        classified_expire_days: Yup.string()
            .matches(/^\d+$/, "Must be digits and characters required are 1."),            
        classified_is_hide: Yup.number().required("You must select Hide or Show"),
    });

    const handleSubmitClick = (adsValues) => {
        axiosAddClassifiedResponse(adsValues)
    }

    const axiosAddClassifiedResponse = async (adsValues) => {
        const formData = new FormData();
        console.log(adsValues)
        formData.append('title', adsValues.classified_title);
        formData.append('description', adsValues.classified_desc);
        formData.append('zip_code', adsValues.classified_zipcode ? adsValues.classified_zipcode : 0);
        formData.append('is_hidden', adsValues.classified_is_hide);
        formData.append('category_id', adsValues.classified_category_id);
        formData.append('status_id', adsValues.classified_status_id);        
        formData.append('user_id', adsValues.classified_users_id);        
        formData.append('district_id', adsValues.classified_district_id);        
        formData.append('state_id', adsValues.classified_state_id);
        formData.append('phone_number', adsValues.classified_phonenum ? adsValues.classified_phonenum : 0);
        formData.append('to_be_expire_on', adsValues.classified_expire_days ? adsValues.classified_expire_days : 0);
        if (adsValues.classified_image !== '') {
            formData.append('image_file_name', adsValues.classified_image, adsValues.classified_image.name);
        }

        const headers = {"content-type": "multipart/form-data"}
        AppService
        .makeRequestWithPostAndHeaders(CLASSIFIED_URL, formData, { headers })
        .then(response => {          
            history('/superadmin/myads')
            toast.success(response.message)
        })
        .catch((error) => {
            toast.error(error.message)
        })        
    }

    const onStateChange = async (stateValueId, setFieldValue) => {
        setFieldValue('classified_district_id', '')
        if (stateValueId) {
            AppService
            .makeRequestWithQueryString(DISTRICT_BY_STATE_ID_URL + stateValueId)
            .then(districResponse => {          
                setAllDistrict(districResponse.data)
            })
            .catch(() => {})            
        }
    }
    
    useEffect(() => {
		window.scrollTo(110, 110)
		Promise.all([
			AppService.makeRequest(CATEGORY_URL),
			AppService.makeRequest(STATUS_URL),
			AppService.makeRequest(STATE_URL),
		]).then(([categoryResponse, statusResponse, stateResponse]) => {
			setAllCategory(categoryResponse.data)
			setAllStatus(statusResponse.data)
			setAllState(stateResponse.data)
		})        
    }, [])

    return (
        <Formik initialValues={initialValues} validationSchema={adsSchema} onSubmit={(values) => { handleSubmitClick(values); }}>
            {(formik) => {
                const { handleChange, setFieldValue, values } = formik;
                return (
                    <div className="container py-5">
                        <Form>
                            <div className="row">
                            <div className="col-md-8">
                                    <h3 className="linetitle mb-4">Add Classifieds</h3>
                                    <div className="form-group">
                                        <Field className="form-control" id="classified_title" type="text" name="classified_title" placeholder="Title of the advertisement" />
                                        <ErrorMessage name="classified_title" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-file">
                                            <input className="custom-file-input" id="classified_image" onChange={(e) => {setFieldValue("classified_image", e.target.files[0]); setImagePath(e.target.files[0].name)}} type="file" accept="image/png, image/jpeg" name="classified_image" />
                                            <label className="custom-file-label" htmlFor="classified_image">{imgPath !=='' ? imgPath : 'Upload the image of the advertisement'}</label>
                                            <ErrorMessage name="classified_image" component="span" className="error small text-danger" /></div>
                                    </div>                                    
                                    <div className="form-group border border-secondary">
                                        <Editor
                                            editorStyle={{ height: '300px' }}
                                            className="form-control"
                                            id="classified_desc"    
                                            type="text" 
                                            name="classified_desc"
                                            placeholder="Description of the Advertisement"
                                            defaultContentState={initialValues.classified_desc}
                                            onContentStateChange={(editorState) => 
                                                setFieldValue("classified_desc", draftToHtml(editorState))
                                            } 
                                        />  
                                        <ErrorMessage name="classified_desc" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label" >Advertisement Category :</label>
                                        <Field as="select" name="classified_category_id" className="custom-select">
                                            <option value="">Select</option>
                                            {allCategory ?
                                                allCategory.map((category, index) => {
                                                    return <option key={index} value={category.id}>{category.category}</option>
                                                }) : <option >none</option>
                                            }
                                        </Field>
                                        <ErrorMessage name="classified_category_id" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label" >Advertisement Status :</label>
                                        <Field as="select" name="classified_status_id" className="custom-select">
                                            <option value="">Select</option>
                                            {allStatus ?
                                                allStatus.map((status, index) => {
                                                    return <option key={index} value={status.id}>{status.status}</option>
                                                }) : <option >none</option>
                                            }
                                        </Field>
                                        <ErrorMessage name="classified_status_id" component="span" className="error small text-danger" />
                                    </div>                                    
                                    <div className="form-group">
                                        <label className="form-control-label" >Advertisement Location State :</label>
                                        <Field as="select" value={values.classified_state_id} name="classified_state_id" onChange={(e) => { handleChange(e); onStateChange(e.target.value, setFieldValue); }} className="custom-select">
                                            <option value="">Select</option>
                                            {allState ?
                                                allState.map((state, index) => {
                                                return <option key={index} value={state.id}>{state.state}</option>
                                            }) : <option >none</option>
                                        }
                                        </Field>
                                        <ErrorMessage name="classified_state_id" component="span" className="error small text-danger" />
                                    </div>                    
                                    <div className="form-group">
                                        <label className="form-control-label" >Advertisement Location District :</label>
                                        <Field as="select" name="classified_district_id" className="custom-select">
                                            <option value="">Select</option>
                                            {allDistrict ?
                                                allDistrict.map((district, index) => {
                                                return <option key={index} value={district.id}>{district.district}</option>
                                            }) : <option >none</option>
                                        }
                                        </Field>
                                        <ErrorMessage name="classified_district_id" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <Field className="form-control" id="classified_zipcode" type="text" name="classified_zipcode" placeholder="Zip code of advertiser" />
                                        <ErrorMessage name="classified_zipcode" component="span" className="error small text-danger" />
                                    </div>                    
                                    <div className="form-group">
                                        <Field className="form-control" id="classified_phonenum" type="text" name="classified_phonenum" placeholder="Contact number of advertisement" />
                                        <ErrorMessage name="classified_phonenum" component="span" className="error small text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <Field className="form-control" id="classified_expire_days" type="text" name="classified_expire_days" placeholder="Number of days within advertisement will expire" />
                                        <ErrorMessage name="classified_expire_days" component="span" className="error small text-danger" />
                                    </div>                                    
                                    <div className="form-group">
                                        <div className="custom-control-inline">
                                            <Field className="margin:5px" id="classified_is_hide" type="radio" name="classified_is_hide" value="1"/>
                                            <label >Hide advertisement </label>                                            
                                        </div>
                                        <div className="custom-control-inline">
                                            <Field className="margin:5px" id="classified_is_hide" type="radio" name="classified_is_hide" value="0"/>
                                            <label>Show advertisement  </label>
                                        </div>
                                        <ErrorMessage name="classified_is_hide" component="span" className="error small text-danger" />                                          
                                    </div>                                                           
                                    <div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                            </div>
                            </div>
                        </Form>
                    </div>                    
                );
            }}
        </Formik>    
    )
      
  }

export default AddClassifiedsComponent;