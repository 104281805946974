
export const BASE_API_URL = 'https://www.janathads.com/api/'
export const BASE_URL = 'https://www.janathads.com/'

// export const BASE_API_URL = 'http://127.0.0.1:5000/api/' || 'http://localhost/'
// export const BASE_URL = 'http://127.0.0.1:5000/' || 'http://localhost/'

export const USER_AVATAR_URL =  BASE_API_URL + 'media/user_avatar.jpg'

export const CATEGORY_URL = BASE_API_URL + 'category/'
export const CATEGORY_GET_BY_PUBLCIC_ID_URL = BASE_API_URL + 'category/update_category/public_id='

export const STATE_URL = BASE_API_URL + 'state/'

export const DISTRICT_URL = BASE_API_URL + 'district/'
export const DISTRICT_BY_STATE_URL = BASE_API_URL + 'district/districts_state'
export const DISTRICT_BY_STATE_ID_URL = BASE_API_URL + 'district/state_id='

export const STATUS_URL = BASE_API_URL + 'status/'
export const STATUS_GET_BY_PUBLIC_ID_URL = BASE_API_URL + 'status/update_status/public_id='

export const CLASSIFIED_URL = BASE_API_URL + 'classified/'
export const CLASSIFIED_BY_PUBLIC_ID_URL = BASE_API_URL + 'classified/public_id='
export const CLASSIFIED_BY_PUBLIC_ID_SM_URL = BASE_URL + 'classified/?public_id='
export const CLASSIFIED_IMAGE_URL = BASE_API_URL + 'media/'

export const USER_URL = BASE_API_URL + 'user/'
export const USER_BY_PUBLIC_ID_URL = BASE_API_URL + 'user/public_id='

export const CHANGE_PASSWORD_URL = BASE_API_URL + 'user/change_password'

export const CLASSIFIED_HIDE_AT_ONCE_URL = BASE_API_URL + 'classified/hideatonce/'

export const USER_AUTH_LOGIN_URL = BASE_API_URL + 'auth/login'