
import banner from '../utilities/images/home/hero.jpg';

function AboutUsComponent() {

    return (
        <>
            <section className="hero-area bg-1 text-center text-white overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-block">
                                <h1 className="display-4 mb-3">About Us</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container bg-white mt-n5 z-index-1 position-relative rounded shadow p-4 mb-4">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="linetitle">Who We Are</h1>
                            <p>The advertising industry is something that helps all kinds of people around. So we have a platform Janathads, a digital platform you need. It's a place where you can post your business ads at a perfectly low cost. And for all the customers, it's the right place where you can find the things you need. This digital platform is for a wide range of advertisements. It can be your new hotel or a new bakery you opened. It may also include textile stores or a house you want to give on rent. There are absolutely no restrictions on the genre of advertisements you want to publish. </p>
                            <p>The advertisement is going to be posted according to customer needs, which is exactly what you are looking for. The timeline of your advertisement can be decided on your needs. One can reach out to their customers easily through our platform. It's going to be divided into categories and will appear to those who search keywords on the internet. Advertisements will be posted according to locations as well which will be helpful to both customers as well as business owners.</p></div>
                        <div className="col-md-4">
                            <img src={banner} alt="My Ads" className="rounded-xl my-4 shadow-lg img-fluid" /></div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default AboutUsComponent;