function ContactUsComponent() {

    return (
        <>
            <section className="hero-area bg-1 text-center text-white overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-block">
                                <h1 className="display-4 mb-3">Contact Us</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container bg-white mt-n5 z-index-1 position-relative rounded shadow p-4 mb-4">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="linetitle">Get In Touch</h1>
                            <p>For any queries and quick support please reach us. </p>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item bg-transparent border-0 py-2 px-0">
                                        <i className="fa fa-map text-info mr-3"></i> 
                                        Bejai Main Road, Shop No:406, 4th Floor, Guru Plaza,
                                    </li>
                                    <li className="list-group-item bg-transparent border-0 py-2 px-65">
                                        Near to KSRTC Bus Stand, Mangaluru, Karnataka 575001
                                    </li>
                                    <li className="list-group-item bg-transparent border-0 py-2 px-0">
                                        <i className="fa fa-phone text-info mr-3"></i> +91 6360060154
                                    </li>
                                    <li className="list-group-item bg-transparent border-0 py-2 px-0"><i className="fa fa-envelope-o text-info mr-3">
                                        </i> janathads@yahoo.com
                                    </li>
                                </ul>
                        </div>
                        <div className="col-md-4">
                            <iframe title="janathads" src="https://maps.google.com/maps?q=Guru%20Plaza,%20Bejai%20Main%20Rd,&t=&z=13&ie=UTF8&iwloc=&output=embed" width="" height="450" allowFullScreen="" loading="lazy" className="border-0"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default ContactUsComponent;