import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';
import banner from '../utilities/images/home/hero.jpg';
import { AppService } from '../services/app.service';
import { useContext } from "react";
import { UserContext } from "../components/context/UserContext.js";

import { USER_BY_PUBLIC_ID_URL, CLASSIFIED_URL, CLASSIFIED_IMAGE_URL, USER_AVATAR_URL } from '../constants/url.constants';

function MyAdsComponent() {
	const [allClassifieds, setAllClassifieds] = useState('');
	const [originalAllClassifieds, setOriginalAllClassifieds] = useState('');	
	const [userDetail, setUserDetail] = useState('');
	const [searchText, setSerachText] = useState('');
	const [deleted, setDeleted] = useState(false);

    const { contextState, contextDispatch } = useContext(UserContext);
    const userContextDetails = JSON.parse(contextState)

	useEffect(() => {
		window.scrollTo(110, 110)
		let build_query_3 = `user_id=`+ userContextDetails.id
		setDeleted(false)
		Promise.all([ 
			AppService.makeRequest(USER_BY_PUBLIC_ID_URL + userContextDetails.public_id),
			// AppService.makeRequestWithQueryString(CLASSIFIED_URL, build_query_3),			
			AppService.makeRequestWithQueryString(CLASSIFIED_URL),			
		]).then(([userDetailResponse, classifiedResponse]) => {
			setUserDetail(userDetailResponse)
			setAllClassifieds(classifiedResponse.data)
			setOriginalAllClassifieds(classifiedResponse.data)
		})
	}, [userContextDetails.public_id, userContextDetails.id, deleted])	


	const handleChange = (searchFieldText) => {
		setSerachText(searchFieldText)
		searchFieldText = searchFieldText.toLowerCase().trim()

		const filteredData = originalAllClassifieds.filter(item => {
			return Object.keys(item).some(key => {
				if (item[key]) {
					return item[key].toString().toLowerCase().includes(searchFieldText)
				} else {
					return false;
				}
			})
		})
		setAllClassifieds(filteredData)		
	}


	const clearSearchText = (e) => {
		e.preventDefault();
		setSerachText('')
		setAllClassifieds(originalAllClassifieds)
	}

	const deleteClassified = (classified_public_id) => {
        AppService
        .makeRequest(CLASSIFIED_URL + 'public_id='+classified_public_id, 'delete')
        .then(res => {
			setDeleted(true)
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })
	}
	

	function findDayDifference(adUpdatedDate) {
		const current_date = new Date();
		adUpdatedDate = new Date(adUpdatedDate);
		return Math.floor((Math.abs(current_date - adUpdatedDate)) / (1000 * 60 * 60 * 24));
	}

	return (
		<div>

			<section className="dashboard section">
				<div className="container">
					<img src={banner} alt="My Ads" className="rounded-xl my-4 shadow-lg img-fluid" />
					<div className="row">
						<div className="col-lg-3">
							<div className="sidebar">
								<div className="widget user-dashboard-profile">
									<div className="profile-thumb">
										<img className="rounded-circle shadow-lg" src={USER_AVATAR_URL} alt="user-avatar" />
									</div>
									<h5 className="text-center">{userDetail.f_name + ' ' + userDetail.l_name}</h5>
									<p className="text-center small text-muted">Joined : {userDetail.registered_on ? new Date(userDetail.registered_on).toISOString().slice(0, 10) : ''}</p>

								</div>
								<div className="widget user-dashboard-menu">
									<ul className="list-group list-group-flush">
										<li className="list-group-item" ><Link to="/superadmin/myads" className="text-dark text-decoration-none">
											<i className="fa fa-user mr-3"></i> My Total Ads : <span>{allClassifieds.length}</span></Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-9">
							<div className="bg-white mt-lg-n5 z-index-1 p-4 rounded-lg">
								<div className="block d-flex mb-4">
									<input type="text" onChange={(e) => handleChange(e.target.value)} value={searchText} className="form-control mb-2 mr-sm-2 mb-sm-0" id="ad_search" placeholder="Search for ads" />
									<button type="submit" onClick={clearSearchText} className="btn btn-danger">Clear</button>
								</div>
								<h3 className="heading">My Ads</h3>
								<div className="table-responsive">
									<table className="table product-dashboard-table">
										<thead>
											<tr>
												<th>Image</th>
												<th className="">Ad Info</th>
												<th className="">Ad Category</th>
												<th className="">Ad Is Hidden</th>
												<th className="">Ad Expire On</th>
												<th className="">How Old Ad is</th>
												<th className="">Action</th>
											</tr>
										</thead>
										<tbody>
											{allClassifieds ?
											Object.keys(allClassifieds).map((index) => {
													return (
														<tr key={index} bgcolor={allClassifieds[index].is_hidden === 'True'  ? "grey" : "" }>
															<td>
																<div className="ranbdom-color"><img width="80px" height="auto" src={allClassifieds[index].image_file_name ? CLASSIFIED_IMAGE_URL + allClassifieds[index].image_file_name : ""} alt="no_ads" /></div></td>

															<td className="product-details">
																<p className="font-weight-bold mb-1">{allClassifieds[index].title ? allClassifieds[index].title : "No Title"}</p>
																<p className="status active mb-0"><span className="badge badge-pill badge-success font-weight-normal">{allClassifieds[index].status.status ? allClassifieds[index].status.status : "No Status"}</span></p>
																<p className="mb-0"><i className="fa fa-calendar mr-2"></i><time>{new Date(allClassifieds[index].updated_on).toISOString().slice(0, 10)}</time> </p>
																<p className="location mb-0"><i className="fa fa-map-marker mr-2"></i>{allClassifieds[index].district.district}</p>
															</td>
															<td className="product-category"><span className="categories">{allClassifieds[index].category.category}</span></td>
															<td className="product-category"><span className="categories">{allClassifieds[index].is_hidden === 'True' ? "Yes" : "No"}</span></td>
															<td className="product-category"><span className="categories">{allClassifieds[index].to_be_expire_on}</span></td>
															<td className="product-category"><span className="categories">{findDayDifference(allClassifieds[index].updated_on)}</span></td>
															<td className="action" data-title="Action">
																<div className="">
																	<ul className="list-inline justify-content-center text-nowrap">
																		<li className="list-inline-item">
																			<Link to={"/classified/?public_id="+allClassifieds[index].public_id} target={"_blank"} data-toggle="tooltip" data-placement="top" title="View Ad" className="view btn btn-sm btn-info" >
																				<i className="fa fa-eye"></i>
																			</Link>
																		</li>
																		<li className="list-inline-item">
																			<Link className="edit btn btn-sm btn-warning" title="Edit Ad" to={"/superadmin/classified_update/?public_id=" + allClassifieds[index].public_id}>
																				<i className="fa fa-pencil"></i>
																			</Link>
																		</li>
																		<li className="list-inline-item">
																			<Link to="/superadmin/myads" onClick={() => deleteClassified(allClassifieds[index].public_id)} className="delete btn btn-sm btn-danger" title="Delete Ad">
																				<i className="fa fa-trash"></i>
																			</Link>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													)
												})
												:
												<tr>
													<td className="product-details">None</td>
												</tr>
											}
										</tbody>
									</table>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>


		</div>
	)
}

export default MyAdsComponent;