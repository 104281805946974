import React, { useState, useEffect } from 'react';
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify'

import { STATE_URL, DISTRICT_URL, DISTRICT_BY_STATE_URL } from '../constants/url.constants';

function AddDistrictComponent() {
    const [districtVal, setDistrictVal] = useState('');
    const [stateId, setStateId] = useState('');
    const [stateName, setStateName] = useState('');
    const [allState, setAllState] = useState('');
    const [allStateDistrict, setAllStateDistrict] = useState('');
    const [publicId, setPublicId] = useState('');    

    const handleDistrict = async () => {
        if(districtVal === 0 || districtVal === ''){
            toast.error('Invalid district')
            return
        }
        AppService
        .makeRequestWithPost(DISTRICT_URL, {'district': districtVal, 'state_id': stateId.toString(), 'public_id': publicId ? publicId : ''})
        .then(res => { 
            setDistrictVal('')
            setPublicId('')
            axiosDistrictResponse()
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })

    }

    const editDistrict = (district) => {
        setPublicId(district.public_id)
        setDistrictVal(district.district)
        setStateName(district.state.state)
        setStateId(district.state.id)
    }

    const deleteDistrict = (district) => {
        AppService
        .makeRequestDeleteByPostData(DISTRICT_URL, {"public_id": district.public_id})
        .then(res => {
            axiosDistrictResponse()
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })            
    }

    const axiosDistrictResponse = async () => {
        AppService
        .makeRequest(STATE_URL)
        .then(state_data => { 
			setAllState(state_data.data)
        })
        .catch((res) => {
            toast.error(res.message)
        })

        AppService
        .makeRequest(DISTRICT_BY_STATE_URL)
        .then(state_district_data => { 
			setAllStateDistrict(state_district_data.data)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }


    useEffect(() => {
        axiosDistrictResponse()        
    }, [])

    return (
        <div className="container py-5">
            <h4 className="linetitle mb-4">Add District</h4>
            <div className="row">
                <div className="col-md-8">
                    <div className="form-inline mb-3">
                        <select className="custom-select mr-3" onChange={(e) => setStateId(e.target.value) }>
                            {stateName ? '' : <option value="0">Select State</option>}
                            {allState ?
                                allState.map((state, index) => {
                                    return (
                                        <option key={index} value={ state.id} >{  stateName ? stateName : state.state}</option>
                                    )
                                }) : <option >none</option>
                            }
                        </select>

                        <div className="input-group">
                            <input onChange={(e) => setDistrictVal(e.target.value)} value={districtVal} className="form-control" id="title" type="text" placeholder="Add District here.." />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" onClick={handleDistrict} type="submit" id="button-addon2">Add</button>
                            </div>
                        </div>
                    </div>

                    <ul className="list-group list-group-flush">
                        {allStateDistrict ? (
                            allStateDistrict.map(function (eachDistrict, index) {
                                return (
                                    <li className="list-group-item d-flex px-0" key={index}> [ {eachDistrict.state.state} ]   --  {eachDistrict.district}
                                        <button onClick={() => editDistrict(eachDistrict) } className="btn btn-sm btn-warning ml-auto mr-2"><i className="fa fa-pencil mr-1"></i>Edit</button>
                                        <button onClick={() => deleteDistrict(eachDistrict)} className="btn btn-sm btn-danger delete"><i className="fa fa-trash mr-1"></i>Delete</button>
                                    </li>

                                )
                            })
                        ) : null}
                    </ul>

                </div>
            </div></div>
    )

}

export default AddDistrictComponent;