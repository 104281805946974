import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppService } from '../services/app.service';
import { userService } from '../services/user.service';
import { toast } from 'react-toastify'

import { CHANGE_PASSWORD_URL, USER_BY_PUBLIC_ID_URL } from '../constants/url.constants';

function ForgotPasswordComponent() {
    const location = useLocation(); 
    const query_params = new URLSearchParams(location.search)  
    const edit_profile_id = query_params.get('v')

    const public_id = userService.getAuthBaseInfo()
    const [adminUsersListResponse, setAdminUsersListResponse] = useState('')

    const history = useNavigate();
    const SignInSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required"),  
        password: Yup.string()
            .required("Password is required")
            .min(7, "Password is too short - should be 7 chars minimum")
            .max(200, "Password is too long - should be less than 200 chars"),
    });

    let initialValues = { email: "", password: "" };    
    if(edit_profile_id === '2'){
        initialValues = { 
            email: adminUsersListResponse.email, 
            password: "" };
    }

    async function setPassword(setPasswordData) {
        AppService
        .makeRequestWithPost(CHANGE_PASSWORD_URL, {'email':setPasswordData.email , 'password':setPasswordData.password})
        .then(adminResponse => {          
            toast.success(adminResponse.message)
            localStorage.clear()
            history('/superadmin/logout')
        })
        .catch((message) => {
            toast.error(message.message)
        })
    }

    function handleCacelClick() {
        history('/')
    }

    function userloggedInUser() {
        AppService
        .makeRequest(USER_BY_PUBLIC_ID_URL + public_id)
        .then(adminUserResponse => {   
            setAdminUsersListResponse(adminUserResponse)
        })
        .catch((error) => {
            toast.error(error.message)
        })
    }    

    useEffect(() => {
        if(edit_profile_id === '2'){
            userloggedInUser()
        }
    }, [edit_profile_id])


    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={SignInSchema} onSubmit={(values) => { setPassword(values); }}>
            {(formik) => {
                return (
                    <div id="task-container" className="container py-5">
                        <div className="row d-flex align-items-center justify-content-center h-50">
                            <div className="col-md-6 col-lg-4">
                                <Form className="shadow p-5">
                                    {edit_profile_id === '2' ?
                                        <div>
                                            <h4 className="heading text-center mb-4">Reset Your Password</h4>
                                            <div className="form-group">
                                                <Field type="text" name="email" id="email" readonly='true' className="form-control" placeholder="email" />
                                                <ErrorMessage name="email" component="span" className="error" />
                                            </div>
                                            <div className="form-group">
                                                <Field type="password" name="password" id="password" className="form-control" placeholder="New password" />
                                                <ErrorMessage name="password" component="span" className="error" />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-sm btn-block">Reset Password</button>
                                                <button onClick={handleCacelClick} className="btn btn-link btn-sm btn-block">Cancel</button>
                                            </div>
                                        </div>                                    
                                    :
                                        <div>
                                            <h4 className="heading text-center mb-4">Change Password</h4>
                                            <div className="form-group">
                                                <Field type="text" name="email" id="email" className="form-control" placeholder="email" />
                                                <ErrorMessage name="email" component="span" className="error" />
                                            </div>
                                            <div className="form-group">
                                                <Field type="password" name="password" id="password" className="form-control" placeholder="New password" />
                                                <ErrorMessage name="password" component="span" className="error" />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-sm btn-block">Update Password</button>
                                                <button onClick={handleCacelClick} className="btn btn-link btn-sm btn-block">Cancel</button>
                                            </div>
                                        </div>                                
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );

}

export default ForgotPasswordComponent;