let initial_val = JSON.stringify({ 'logged_in': false, 'id': null, 'public_id': null , 'name': null })
const user_public_id = localStorage.getItem('base_info')
if (user_public_id && user_public_id !== ''){
    const user_info = JSON.parse(localStorage.getItem('user_info'))
    initial_val = JSON.stringify({ 'logged_in': true, 'id': user_info.u_id, 'public_id': user_public_id , 'name': user_info.u_name })
}

export const initialState = initial_val

export const reducer = (state, action) => {
    if(action.type === 'user'){
        return JSON.stringify(action.payload);
    }
    return state;
}
