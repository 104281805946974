import { useLocation  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { AppService } from '../services/app.service';

import { CLASSIFIED_BY_PUBLIC_ID_URL, CLASSIFIED_IMAGE_URL } from '../constants/url.constants';

function ViewSingleClassifiedComponent() {
    const [classifiedDetails, setClassifiedDetails] = useState('');

    const location = useLocation()
    const query_params = new URLSearchParams(location.search)  
    const classified_public_id = query_params.get('public_id')

    useEffect(() => {
        AppService
        .makeRequest(CLASSIFIED_BY_PUBLIC_ID_URL + classified_public_id)
        .then(classified_data => { 
			setClassifiedDetails(classified_data)
        })
        .catch((message) => {
            toast.error(message.message)
        })
    }, [classified_public_id])


    return (
        <div>
            <section className="hero-area bg-1 text-center text-white overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-block">
                                <h1 className="display-4 mb-3">{classifiedDetails.title ? classifiedDetails.title : ''}</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container bg-white mt-n5 z-index-1 position-relative rounded shadow p-4 mb-4">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>{classifiedDetails.title ? classifiedDetails.title : 'No Title'} 
                                <span className="badge badge-success">{classifiedDetails && classifiedDetails.status.status ? classifiedDetails.status.status : ' No Status '}</span>
                            </h2>
                            <p className="text-muted pt-4" dangerouslySetInnerHTML={{ __html: classifiedDetails.description && classifiedDetails.description !== '[object Object]' && classifiedDetails.description !== '' ? classifiedDetails.description.replace('/\n/g', '<br />') : ' No Description '}}></p>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item pl-0"><i className="fa fa-phone mr-3"></i>{typeof classifiedDetails.phone_number ? classifiedDetails.phone_number : ''}</li>
                                <li className="list-group-item pl-0"><i className="fa fa-map-o mr-3"></i>{classifiedDetails && classifiedDetails.state.state ? classifiedDetails.state.state : ''}</li>
                                <li className="list-group-item pl-0"><i className="fa fa-map-marker mr-3"></i>{classifiedDetails && classifiedDetails.district.district ? classifiedDetails.district.district : ''}</li>
                                <li className="list-group-item pl-0"><i className="fa fa-calendar mr-3"></i>{classifiedDetails.updated_on ? new Date(classifiedDetails.updated_on).toISOString().slice(0, 10) : ''}</li>
                            </ul>

                        </div>
                        <div className="col-md-4">

                            {classifiedDetails.image_file_name ?
                                <div className="">
                                    <img className="card-img-top img-fluid" src={CLASSIFIED_IMAGE_URL + classifiedDetails.image_file_name} alt="classified poster" />
                                </div>
                                : ''
                            }</div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ViewSingleClassifiedComponent;