import { Link } from 'react-router-dom';
import logo from '../utilities/images/logo-footer.png';

function FooterComponent() {
  return (
    <div>
      <footer className="footer section section-sm text-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="block about">
                <img src={logo} alt="Logo" className="mb-3" />
                <p className="text-size-sm">Janathads is a digital platform is for a wide range of advertisements. This platform built keeping in mind posting ads in low cost and number of days you want it display it. Since it's digital platform it helps reach wider range of people through internet.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block">
                <h4>Quick Links</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><Link to="/" className="text-white">Home</Link></li>
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><Link to="/aboutus" className="text-white">About Us</Link></li>
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><Link to="/contactus" className="text-white">Contact Us</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-2 app-promotion">
                <h4>Contact</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><i className="fa fa-map text-info mr-3"></i> Bejai Main Road, Shop No:406, 4th Floor, Guru Plaza,  Near to KSRTC Bus Stand, Mangaluru, Karnataka 575001</li>
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><i className="fa fa-phone text-info mr-3"></i> +91 6360060154</li>
                  <li className="list-group-item bg-transparent border-0 py-2 px-0"><i className="fa fa-envelope-o text-info mr-3"></i> janathads@yahoo.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer-bottom text-white py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="copyright">
                <p className="mb-0 small text-uppercase">Copyright © 2022. All Rights Reserved</p>
              </div>
            </div>
            <div className="col-sm-6 col-12 text-right">
              <Link className="fa fa-facebook text-white px-3 text-decoration-none" to=""></Link>
              <Link className="fa fa-twitter text-white px-3 text-decoration-none" to=""></Link>
              <Link className="fa fa-whatsapp text-white px-3 text-decoration-none" to=""></Link>
            </div>
          </div>
        </div>
      </footer>

    </div>
  )

}

export default FooterComponent;