import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserContext } from '../components/context/UserContext.js'
import React, { useReducer, useEffect } from 'react';

import HeaderComponent from './HeaderComponent'
import HomePageComponent from './HomePageComponent'
import FooterComponent from './FooterComponent'
import LoginComponent from './LoginComponent';
import LogoutComponent from './LogoutComponent';
import AddUpdateAdminComponent from './AddUpdateAdminComponent';
import AddClassifiedsComponent from './AddClassifiedsComponent';
import AddCategoryComponent from './AddCategoryComponent';
import AddStatusComponent from './AddStatusComponent';
import MyAdsComponent from './MyAdsComponent';
import AddStateComponent from './AddStateComponent';
import AddDistrictComponent from './AddDistrictComponent';
import ClassifiedUpdateComponent from './ClassifiedUpdateComponent';
import ContactUsComponent from './ContactUsComponent';
import AboutUsComponent from './AboutUsComponent';
import ViewSingleClassifiedComponent from './ViewSingleClassifiedComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import HideAdsBulkUpdateComponent from './HideAdsBulkUpdateComponent';
import UserReportComponent from './UserReportComponent';
import ScrollToTop from './ScrollToTop';
import PrivateRoute from './PrivateRoute';

import { initialState, reducer } from '../components/reducer/UseReducer'

function LandingComponent(props) {
    const [contextState, contextDispatch] = useReducer(reducer, initialState)

    useEffect(() => {
		window.scrollTo(0,0)
	}, [])

    return (
        <div>
            {/* provide the folder pathe in basename where files are hosted */}
            <BrowserRouter basename={''}>             
            {/* <BrowserRouter basename={'/demo/sajan/adsfrontend'}> */}
                <UserContext.Provider value={{contextState, contextDispatch}}>
                <HeaderComponent></HeaderComponent>
                    <ScrollToTop />
                    <Routes>
                        <Route path="*" element={< HomePageComponent />} /> {/* To handle any invalid react route*/}
                        <Route exact path='/' element={< HomePageComponent />}></Route>
                        <Route exact path='/contactus' element={< ContactUsComponent />}></Route>
                        <Route exact path='/aboutus' element={< AboutUsComponent />}></Route>                    
                        <Route exact path='/classified/' element={<ViewSingleClassifiedComponent />}></Route>                    

                        <Route exact path='/superadmin' element={<LoginComponent />}></Route>
                        <Route exact path='/superadmin/login' element={<LoginComponent />}></Route>
                        <Route exact path='/superadmin/logout' element={<LogoutComponent />}></Route>                    
                        <Route exact path='/superadmin/forgotpassword' element={< ForgotPasswordComponent />}></Route>
                        <Route exact path='/superadmin/myads' element={<PrivateRoute> <MyAdsComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/classifieds' element={<PrivateRoute> <AddClassifiedsComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/classified_update/' element={<PrivateRoute> <ClassifiedUpdateComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/category' element={<PrivateRoute> <AddCategoryComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/status' element={<PrivateRoute> <AddStatusComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/state' element={<PrivateRoute> <AddStateComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/district' element={<PrivateRoute> <AddDistrictComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/hideadsatonce' element={<PrivateRoute> <HideAdsBulkUpdateComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/useradminregistration/' element={<PrivateRoute> <AddUpdateAdminComponent /> </PrivateRoute>}></Route>
                        <Route exact path='/superadmin/userreport' element={<PrivateRoute> <UserReportComponent /> </PrivateRoute>}></Route>  
                                                
                    </Routes>
                <FooterComponent></FooterComponent>
                </UserContext.Provider>
            </BrowserRouter>
        </div>
    )
      
  }

export default LandingComponent;