import React, { useState, useEffect } from 'react';
import { AppService } from '../services/app.service';
import { toast } from 'react-toastify'

import { STATUS_URL, STATUS_GET_BY_PUBLIC_ID_URL } from '../constants/url.constants';

function AddStatusComponent() {
    const [addStatus, setAddStatus] = useState('');
    const [allStatus, setAllStatus] = useState('');
    const [publicId, setPublicId] = useState('');

    const handleStatus = async () => {
        if(addStatus === 0 || addStatus === ''){
            toast.error('Invalid status')
            return
        }
        if (publicId) {
            AppService
            .makeRequestWithPost(STATUS_GET_BY_PUBLIC_ID_URL + publicId, {'status': addStatus})
            .then(res => {
                setAddStatus('');
                setPublicId('')                
                axiosStatusResponse()
                toast.success(res.message)
            })
            .catch((res) => {
                toast.error(res.message)
            })
        } else {
            AppService
            .makeRequestWithPost(STATUS_URL, {'status': addStatus})
            .then(res => { 
                setAddStatus('');
                toast.success(res.message)
                axiosStatusResponse()
            })
            .catch((res) => {
                toast.error(res.message)
            })            
        }
    }

    const editStatus = (status) => {
        setPublicId(status.public_id)
        setAddStatus(status)
    }

    const deleteStatus = async (status) => {
        AppService
        .makeRequestDeleteByPostData(STATUS_URL, {"public_id": status.public_id})
        .then(res => {
            axiosStatusResponse()
            toast.success(res.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }

    const axiosStatusResponse = async () => {
        AppService
        .makeRequest(STATUS_URL)
        .then(status_data => { 
			setAllStatus(status_data.data)
            toast.success(status_data.message)
        })
        .catch((res) => {
            toast.error(res.message)
        })
    }

    useEffect(() => {
        axiosStatusResponse()
    }, [])    


    return (
        <div className="container py-5">
            <h4 className="linetitle mb-4">Add Status</h4>
            <div className="row">
                <div className="col-md-8">
                    <div className="input-group mb-3">
                        <input onChange={(e) => setAddStatus(e.target.value)} value={publicId ? addStatus.status : addStatus} className="form-control" id="title" type="text" placeholder="Add Status here.." />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={handleStatus} type="submit" id="button-addon2">Add</button>
                        </div>
                    </div>

                    <ul className="list-group list-group-flush">
                        {allStatus ? (
                            allStatus.map(function (eachStatus, index) {
                                return (
                                    <li className="list-group-item d-flex px-0" key={index}>
                                        {eachStatus.status}
                                        <button onClick={() => editStatus(eachStatus)} className="btn btn-sm btn-warning ml-auto mr-2"><i className="fa fa-pencil mr-1"></i>Edit</button>
                                        <button onClick={() => deleteStatus(eachStatus)} className="btn btn-sm btn-danger delete"><i className="fa fa-trash mr-1"></i>Delete</button></li>
                                )
                            })
                        ) : null}
                    </ul>

                </div>
            </div>
        </div>
    )

}

export default AddStatusComponent;